import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/reservation'

class ReservationService extends ApiService {
  import(id) {
    return http.post(`${this.getUrl()}/${id}/import`)
  }

  getUrl(){
    return URL
  }

  getReservations(id){
    return http.get(`${this.getUrl()}/${id}/get-reservations`);
  }
}

export default new ReservationService()
